import React from 'react';

import Layout from '../components/layout/Layout';
import SEO from '../components/layout/Seo';
import VideoWrapper from '../components/landing/VideoWrapper';
import AboutUs from '../components/landing/AboutUs';
import Team from '../components/landing/Team';
import PhotoGallery from '../components/landing/PhotoGallery';
import Notification from '../components/landing/Notification';

const IndexPage = () => (
  <Layout>
    <SEO title="Strona główna" />
    <VideoWrapper />
    <Notification />
    <AboutUs />
    <Team />
    <PhotoGallery />
  </Layout>
);

export default IndexPage;
