/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useLazyQuery } from 'react-apollo';
import Spinner from '../layout/Spinner';

const PhotoGallery = () => {
  // eslint-disable-next-line no-use-before-define
  const [photos, setPhotos] = useState([]);

  const [fetchData, { loading }] = useLazyQuery(GET_PHOTOS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const newPosts = [...data.posts.nodes].filter((p) => p.featuredImage);
      setPhotos(newPosts);
    },
  });

  useEffect(() => {
    fetchData();
  }, []);

  const renderFeaturedImages = () => {
    if (photos.length > 0) {
      return photos.slice(0, 4).map((photo) => {
        return (
          <div key={photo.id} className="tile-img">
            <img src={photo.featuredImage.node.mediaItemUrl} alt="" />
          </div>
        );
      });
    }
    return null;
  };

  return (
    <section className="gallery">
      <div className="gallery__header">
        <span className="material-icons gallery__icon">camera_alt</span>
        <p>Najnowsze zdjęcia</p>
      </div>
      {loading ? <Spinner /> : <div className="gallery__images">{renderFeaturedImages()}</div>}
    </section>
  );
};

export default PhotoGallery;

const GET_PHOTOS = gql`
  query GetPhotos {
    posts {
      nodes {
        id
        featuredImage {
          node {
            mediaItemUrl
          }
        }
      }
    }
  }
`;
