import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useLazyQuery } from 'react-apollo';
import moment from 'moment';
import 'moment/locale/pl';
import ReactMarkdown from 'react-markdown';

const Notification = () => {
  const LATEST_ELEMENT_INDEX = 0;

  const [notification, setNotification] = useState();
  // eslint-disable-next-line no-use-before-define
  const [fetchData] = useLazyQuery(GET_NOTIFICATIONS, {
    onCompleted: (data) => {
      const notifications = [...data.notifications.nodes];
      const latestNotifications = [];
      notifications.forEach((not) => {
        if (!(moment(not.notification_data.expiryDate) < new Date())) {
          latestNotifications.push(not);
        }
      });
      setNotification(latestNotifications[LATEST_ELEMENT_INDEX]);
    },
  });

  useEffect(() => {
    fetchData();
  }, []);

  const renderNotification = () => (
    <>
      <h3>Aktualności:</h3>
      <ReactMarkdown allowDangerousHtml>{notification.content}</ReactMarkdown>
    </>
  );

  return (
    <div className={`notification ${notification !== undefined ? 'visible' : ''}`}>
      {notification && renderNotification()}
    </div>
  );
};

export default Notification;

const GET_NOTIFICATIONS = gql`
  query GetNotifications {
    notifications {
      nodes {
        id
        content
        notification_data {
          expiryDate
        }
      }
    }
  }
`;
