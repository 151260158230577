import React from 'react';
import team from '../../images/foto_team.png';

const Team = () => {
  return (
    <section className="team">
      <div className="team__image">
        <div className="image-wrapper">
          <img src={team} alt="Jakub Kania, Małgorzata Chojnacka. 2019r." />
          <h3>Jakub Kania, Małgorzata Chojnacka</h3>
        </div>
      </div>
      <div className="team__content">
        <h2>O nas</h2>
        <p>
          Jakub Kania - pierwszy łodzianin tańczący tango argentyńskie. Od 17 lat instruktor i propagator tanga
          argentyńskiego. Uczeń najznamienitszych tancerzy i interpretatorów tanga na świecie takich jak Javier
          Rodriguez, Julio Balmaceda czy Fabian Peralta. Swój warsztat doskonalił także w Argentynie i Urugwaju.
        </p>
        <p>
          Wspólnie z Małgorzatą Chojnacką - założyciel największego w Łodzi i jednego z najprężniejszych w Polsce
          ośrodków kultury tanga argentyńskiego - TANGOŁÓDŹ. Pomysłodawca i inicjator największej imprezy poświęconej
          tangu w Polsce - TANGOŁÓDŹ FESTIVAL, ŁÓDŹ TANGO SALON FESTIVAL, a obecnie Dias de Tango. Prezes Zarządu
          Fundacji Wspierania Kultury FABRYKA TANGA będącej organizatorem festiwalu. Nasze tango uświetniło wiele
          programów telewizyjnych, audycji radiowych i wydarzeń kulturalnych. Występy na deskach Filharmonii i Teatru
          Wielkiego w Łodzi.
        </p>
      </div>
    </section>
  );
};

export default Team;
