import React from 'react';
import about from '../../images/about_us.mp4';

const AboutUs = () => {
  return (
    <section className="about-us">
      <div className="about-us__image">
        <h2>TANGO ŁÓDŹ</h2>
        <video autoPlay muted loop playsInline>
          <source src={about} type="video/mp4" />
          <track />
        </video>
      </div>
      <div className="about-us__content">
        <h2>Tango w Łodzi</h2>
        <p>
          <span>TANGO ARGENTYŃSKIE </span>
          pojawiło się w naszym mieście w 2002 roku. Od tego czasu
          grono jego wielbicieli stale rośnie. Na co dzień wykonujemy
          różne zawody, a różnica wieku między najstarszym i
          najmłodszym łódzkim tanguero wynosi 50 lat. Łączy nas
          przyjaźń i fascynacja tangiem.
        </p>
      </div>
    </section>
  );
};

export default AboutUs;
