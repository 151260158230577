import React from 'react';
import tangoBg from '../../images/tango_bg.mp4';

const VideoWrapper = () => {
  return (
    <section>
      <div className="video-wrapper">
        <div className="color-layer" />
        <video autoPlay muted loop playsInline className="video-wrapper__background">
          <source src={tangoBg} type="video/mp4" />
          <track />
        </video>
        <h1>TangoŁódź</h1>
        <h3>ośrodek kultury i szkoła tanga argentyńskiego</h3>
      </div>
    </section>
  );
};

export default VideoWrapper;
